import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Range, RangeKeyDict, DateRange as ReactDateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export function DateRange({ maxDate, setDateRange }: { maxDate: Date; setDateRange: (startDate: Date, endDate: Date) => void }) {
  const theme = useTheme();
  const [state, setState] = useState<Range[]>([
    {
      startDate: maxDate,
      endDate: maxDate,
      color: theme.palette.primary.main,
      key: 'selection',
    },
  ]);

  function onDateChanged(item: RangeKeyDict) {
    const selection = item.selection;
    if (selection) {
      setDateRange(selection.startDate, selection.endDate);
      setState(() => [selection]);
    }
  }

  return <ReactDateRange maxDate={maxDate} editableDateInputs={false} onChange={onDateChanged} moveRangeOnFirstSelection={false} ranges={state} />;
}
