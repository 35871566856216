import { Suspense, lazy, useMemo, useState } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { ApiError } from '@/api/ui/requests';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import AuditTrail from '@/features/Admin/AuditTrail/AuditTrail';
import BerthSafetyAuditTrail from '@/features/Admin/AuditTrail/BerthSafetyAuditTrail';
import BerthOverview from '@/features/Admin/BerthManagement/BerthOverview';
import BerthSafetyOverview from '@/features/Admin/Dashboard/BerthSafetyOverview';
import MooredVesselOverview from '@/features/Admin/Dashboard/MooredVesselOverview';
import MatrixRowFinder from '@/features/Admin/MatrixRowFinder/MatrixRowFinder';
import PeopleOverview from '@/features/Admin/UserManagement/PeopleOverview';
import Profile from '@/features/Authentication/Profile';
import ProtectedRoute from '@/features/Authentication/ProtectedRoute';
import Token from '@/features/Authentication/Token';
import HistoricOverview from '@/features/SafetyPrediction/History/HistoricOverview';
import SafetyPredictionOperationalView from '@/features/SafetyPrediction/Overview/SafetyPredictionOperationalView';
import SafetyPredictionSimulationView from '@/features/SafetyPrediction/Overview/SafetyPredictionSimulationView';
import { withErrorHandler } from '@/hooks/useErrorBoundary';
import AdminLayout from '@/layouts/AdminLayout';
import DefaultLayout from '@/layouts/DefaultLayout';
import About from '@/pages/About/About';
import DmaCaseLibraryOverview from '@/pages/DmaCaseLibrary/DmaCaseLibraryOverview';
import VesselClassOverview from '@/pages/DmaCaseLibrary/VesselClasses';
import Home from '@/pages/Home/Home';
import NoLicense from '@/pages/NoLicense/NoLicense';
import NotFound from '@/pages/NotFound/NotFound';
import PermissionDenied from '@/pages/PermissionDenied/PermissionDenied';
import ErrorFallbackPage from '@/parts/ErrorFallback/ErrorFallbackPage';
import { ROLE_CONFIGURATION_ADMINISTRATOR, ROLE_DMA_ENGINEER, ROLE_SYSTEM_ADMINISTRATOR } from '@/parts/UserMenu/UserMenu';
import Logout from '@/parts/logout';
import { Analytics } from '@/services/analytics';
import { appSettings } from '@/services/config';
import { LicenseFeatures } from '@/types';
import Auth0ProviderSmartMooring from './features/Authentication/Auth0ProviderSmartMooring';
import AnalyticsSmartMooring from './features/Common/AnalyticsSmartMooring';
import ApiSmartMooring from './features/Common/ApiSmartMooring';
import SmartMooringProvider from './features/Common/SmartMooringProvider';
import MissingFeature from './pages/MissingFeature/MissingFeature';
import { darkTheme, lightTheme } from './theme/themes';

const AdminSection = lazy(() => import('@/pages/Admin/AdminSection'));
const DmaManagement = lazy(() => import('@/./features/Admin/DmaManagement/Overview/DmaOverview'));
const UploadDmaMatrix = lazy(() => import('@/./features/Admin/DmaManagement/Create/CreateDma'));
const MooringLineConfiguration = lazy(() => import('@/./features/Admin/DmaManagement/MooringLineConfiguration/MooringLineConfiguration'));
const OrganisationManagement = lazy(() => import('@/./features/Admin/OrganisationManagement/Overview/OrganisationOverview'));
const AddOrganisation = lazy(() => import('@/./features/Admin/OrganisationManagement/Create/CreateOrganisation'));
const LinkParentOrganisation = lazy(() => import('@/./features/Admin/OrganisationManagement/LinkOrganisation/LinkOrganisation'));
const ViewBerthAssignmentsForOrganisation = lazy(() => import('@/features/Admin/OrganisationManagement/ViewBerthAssignments/ViewBerthAssignments'));
const ConfigureBerthDmaCasesForOrganisation = lazy(
  () => import('@/./features/Admin/OrganisationManagement/ConfigureBerthDmaCases/ConfigureBerthDmaCases')
);
const PurchaseDmaCasesForOrganisation = lazy(() => import('@/features/Admin/OrganisationManagement/ConfigureDmaCases/PurchaseDmaCases'));
const EvaluateDmaCasesForOrganisation = lazy(() => import('@/features/Admin/OrganisationManagement/ConfigureDmaCases/EvaluateDmaCases'));
const ManageUsersForOrganisation = lazy(() => import('@/features/Admin/OrganisationManagement/ManageUsers/ManageUsers'));
const AssignBerthsToOrganisation = lazy(() => import('@/./features/Admin/OrganisationManagement/AssignBerths/AssignBerths'));
const BerthSafetySimulation = lazy(() => import('@/features/SafetyPrediction/BerthDashboard/BerthSafety'));
const VesselSafetyOperational = lazy(() => import('@/features/SafetyPrediction/BerthDashboard/BerthSafety'));
const VesselClearance = lazy(() => import('@/features/VesselClearance/VesselClearanceWizard'));
const MetoceanStations = lazy(() => import('@/features/Admin/MetoceanStationManagement/MetoceanStationsOverview'));

export interface HttpStatus {
  statusCode: number;
  message: string;
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  fontSize: '1.1rem',
  '&.notistack-MuiContent-success': {
    backgroundColor: '#2D7738',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#970C0C',
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#2d83ff',
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#dc740d',
  },
}));

const SentryRoutes = appSettings.sentry.environment == 'local' ? Routes : Sentry.withSentryReactRouterV6Routing(Routes);

function SmartMooringApp() {
  const isDarkMode = false;

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error, query) => {
            const err = error as ApiError;
            if (err.status === 403 || err.status === 401) {
              Analytics.track('PermissionDenied', { err, queryKey: query.queryKey, requestOptions: err.request });
            } else if (err.status === 404) {
              Analytics.track('PageNotFound', { err });
              window.location.href = '/not-found';
            } else {
              Analytics.track('ApiError', { err, query });
            }
          },
        }),
        mutationCache: new MutationCache({
          onError: (error, mutation) => console.log('Error while updating', { error, mutation }),
        }),
        defaultOptions: {
          queries: {
            cacheTime: 1000 * 60 * 15, // 15 minutes
            refetchOnWindowFocus: false,
            useErrorBoundary: false,
            retry: (failureCount, error) => (error as HttpStatus).statusCode === 503 && failureCount <= 3,
            retryDelay: 5000,
          },
        },
      })
  );

  const theme = useMemo(() => createTheme(isDarkMode ? darkTheme : lightTheme), [isDarkMode]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <SnackbarProvider
        preventDuplicate
        dense={true}
        maxSnack={4}
        Components={{
          success: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Auth0ProviderSmartMooring>
              <AnalyticsSmartMooring />
              <ApiSmartMooring />
              <Suspense
                fallback={
                  <div id={'splash-container'}>
                    <LoadingIndicator message={'Loading application data....'} />
                  </div>
                }
              >
                <SmartMooringProvider>
                  <SentryRoutes>
                    <Route element={<ProtectedRoute component={DefaultLayout} />}>
                      <Route path="/about" element={<About />} />
                      <Route path="/vessel-classes" element={<VesselClassOverview />} />
                      <Route path="/dma-case-library" element={<DmaCaseLibraryOverview />} />
                      <Route path="/token" element={<ProtectedRoute component={Token} allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR]} />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/demo-feature" element={<ProtectedRoute component={Profile} licenceFeature="demo feature" />} />
                      <Route path="/missing-feature" element={<MissingFeature />} />
                      <Route path={'/safety-prediction/*'}>
                        <Route index element={<SafetyPredictionSimulationView />} />
                        <Route path={'operational'}>
                          <Route
                            index
                            element={<ProtectedRoute component={SafetyPredictionOperationalView} licenceFeature={LicenseFeatures.AIS_DETECTION} />}
                          />
                          <Route
                            path={'berths/:berthId'}
                            element={<ProtectedRoute component={VesselSafetyOperational} licenceFeature={LicenseFeatures.AIS_DETECTION} />}
                          />
                        </Route>
                        <Route path={'simulation'}>
                          <Route index element={<SafetyPredictionSimulationView />} />
                          <Route path={'berths/:berthId'} element={<BerthSafetySimulation />} />
                        </Route>
                        <Route
                          path={'historical'}
                          element={<ProtectedRoute component={HistoricOverview} />} //licenceFeature={LicenseFeatures.HISTORIC_OVERVIEW} />}
                        />
                        <Route path="*" element={<NotFound />} />
                      </Route>
                      <Route
                        path={'/vessel-clearance'}
                        element={<ProtectedRoute component={VesselClearance} licenceFeature={LicenseFeatures.VESSEL_CLEARANCE} />}
                      />
                    </Route>

                    <Route
                      element={
                        <ProtectedRoute
                          component={AdminLayout}
                          allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR, ROLE_DMA_ENGINEER]}
                        />
                      }
                    >
                      <Route path={'/admin/*'}>
                        <Route index element={<AdminSection />} />
                        <Route
                          path={'dma'}
                          element={
                            <ProtectedRoute
                              component={DmaManagement}
                              allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR, ROLE_DMA_ENGINEER]}
                            />
                          }
                        />
                        <Route
                          path={'dma/create'}
                          element={
                            <ProtectedRoute
                              component={UploadDmaMatrix}
                              allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR, ROLE_DMA_ENGINEER]}
                            />
                          }
                        />
                        <Route
                          path={'dma/:dmaId/mooring-line-configuration'}
                          element={
                            <ProtectedRoute
                              component={MooringLineConfiguration}
                              allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR, ROLE_DMA_ENGINEER]}
                            />
                          }
                        />
                        <Route path={'audit-trail'} element={<AuditTrail />} />
                        <Route path={'audit-trail/berths/:berthId'} element={<BerthSafetyAuditTrail />} />
                        <Route path={'organisations'} element={<OrganisationManagement />} />
                        <Route path={'organisations/create'} element={<AddOrganisation />} />
                        <Route path={'organisations/:organisationId/link'} element={<LinkParentOrganisation />} />
                        <Route path={'organisations/:organisationId/berth-assignments/configure'} element={<AssignBerthsToOrganisation />} />
                        <Route path={'organisations/:organisationId/berth-assignments'} element={<ViewBerthAssignmentsForOrganisation />} />
                        <Route
                          path={'organisations/:organisationId/berth-assignments/:berthId/dma-cases'}
                          element={<ConfigureBerthDmaCasesForOrganisation />}
                        />
                        <Route path={'organisations/:organisationId/dma-cases/purchase'} element={<PurchaseDmaCasesForOrganisation />} />
                        <Route path={'organisations/:organisationId/dma-cases/evaluate'} element={<EvaluateDmaCasesForOrganisation />} />
                        <Route path={'organisations/:organisationId/users'} element={<ManageUsersForOrganisation />} />
                        <Route path={'people'} element={<PeopleOverview />} />
                        <Route path={'berths'} element={<BerthOverview />} />
                        <Route path={'metocean-stations'} element={<MetoceanStations />} />
                        <Route
                          path={'matrix-row-finder'}
                          element={
                            <ProtectedRoute
                              component={MatrixRowFinder}
                              allowedRoles={[ROLE_SYSTEM_ADMINISTRATOR, ROLE_CONFIGURATION_ADMINISTRATOR, ROLE_DMA_ENGINEER]}
                            />
                          }
                        />
                        <Route path={'moored-vessels'} element={<MooredVesselOverview />} />
                        <Route path={'safety-warnings'} element={<BerthSafetyOverview />} />
                        <Route path="*" element={<NotFound />} />
                      </Route>
                    </Route>

                    <Route path="/" element={<Home />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/permission-denied" element={<PermissionDenied />} />
                    <Route path="/no-license" element={<NoLicense />} />
                    <Route path="*" element={<NotFound />} />
                  </SentryRoutes>
                </SmartMooringProvider>
              </Suspense>
            </Auth0ProviderSmartMooring>
          </BrowserRouter>
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default withErrorHandler(SmartMooringApp, ErrorFallbackPage);
