import { ChangeEvent, useCallback, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, Fab, FormControlLabel, Stack } from '@mui/material';
import { FilterFn, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import {
  useBerthManagementServiceGenerateSafetyPredictions,
  useBerthManagementServiceGetBerthSafetyWarnings,
  useBerthManagementServiceListAllAssignedBerths,
} from '@/api/management/queries';
import { BerthDmaCaseSafetyWarning, SafetyPredictionGenerationRequest, SimpleBerth } from '@/api/management/requests';
import { DateRange } from '@/components/common/DateRangePicker/DateRangePicker';
import Select, { SelectedChips } from '@/components/common/Select/Select';
import ApiErrorAlert from '@/components/feedback/Error/ApiErrorAlert';
import LoadingIndicator from '@/components/feedback/LoadingIndicator';
import GridOverview from '@/components/management/GridOverview/GridOverview';
import { DefaultGridOverviewColumnMetadata, GridOverviewColumnMetadata } from '@/components/management/GridOverview/types';
import usePageTitle from '@/hooks/usePageTitle';

const columnHelper = createColumnHelper<BerthDmaCaseSafetyWarning>();

const getTimestampValue = (value: string) => {
  const timestamp = moment(value);
  return timestamp.local().format('DD MMMM HH:mm');
};

const getColumns = () => [
  columnHelper.accessor('berthId', {
    header: 'Identifier',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('dmaCaseId', {
    header: 'DMA',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 2 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('timestamp', {
    header: 'Timestamp',
    cell: (info) => getTimestampValue(info.getValue()),
    filterFn: ((row, columnId, value: string) => {
      const timeStamp = getTimestampValue(row.getValue(columnId) ?? '');

      return !!timeStamp.toLocaleLowerCase().match(value.toLocaleLowerCase());
    }) as FilterFn<BerthDmaCaseSafetyWarning>,
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('percentage', {
    header: 'Percentage',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('loadingCondition', {
    header: 'Loading condition',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
  columnHelper.accessor('vesselDisposition', {
    header: 'Vessel disposition',
    meta: { ...DefaultGridOverviewColumnMetadata, flex: 1 } as GridOverviewColumnMetadata,
  }),
];

function RegenerateSafetyPrediction() {
  const now = new Date();
  const [open, setOpen] = useState(false);
  const [applyAllBerths, setApplyAllBerths] = useState(true);
  const [applyAllHistory, setApplyAllHistory] = useState(true);
  const { data: berths } = useBerthManagementServiceListAllAssignedBerths();
  const [generateSafetyPredictionForm, setGenerateSafetyPredictionForm] = useState<SafetyPredictionGenerationRequest>({
    berthIds: null,
    startDate: null,
    endDate: null,
    withDmaCaseWarnings: true,
    withMooredVesselWarnings: true,
  });
  const { mutateAsync } = useBerthManagementServiceGenerateSafetyPredictions();

  const updateGenerationForm = useCallback(
    (key: keyof SafetyPredictionGenerationRequest, value: SafetyPredictionGenerationRequest[keyof SafetyPredictionGenerationRequest]) => {
      setGenerateSafetyPredictionForm((current) => ({
        ...current,
        [key]: value,
      }));
    },
    [setGenerateSafetyPredictionForm]
  );

  const updateDateRangeForm = (start: Date, end: Date) => {
    updateGenerationForm('startDate', start.toISOString());
    updateGenerationForm('endDate', end.toISOString());
  };

  const onApplyAllBerthsChange = (_: ChangeEvent, c: boolean) => {
    setApplyAllBerths(c);
    if (c) {
      updateGenerationForm('berthIds', null);
    }
  };

  const onApplyAllHistoryChange = (_: ChangeEvent, c: boolean) => {
    setApplyAllHistory(c);
    if (c) {
      updateGenerationForm('startDate', null);
      updateGenerationForm('endDate', null);
    }
  };

  const handleBerthsChange = useCallback(
    (val: Array<string> | string) => {
      const berthIds = typeof val === 'string' ? val.split(',') : val;
      updateGenerationForm('berthIds', berthIds);
    },
    [updateGenerationForm]
  );

  async function syncHistory() {
    await mutateAsync({
      requestBody: { ...generateSafetyPredictionForm },
    });

    setOpen(false);
  }

  return (
    <>
      <Fab variant="extended" color={'primary'} sx={{ position: 'absolute', right: 10, mt: 2.5 }} onClick={() => setOpen(true)}>
        Synchronise safety prediction data
      </Fab>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Stack spacing={1} direction={'column'}>
            <FormControlLabel
              control={<Checkbox checked={applyAllBerths} onChange={onApplyAllBerthsChange} />}
              componentsProps={{ typography: { variant: 'highlightSemiBoldMedium' } }}
              label={'Apply for all berths'}
            />
            {!applyAllBerths && (
              <Select<SimpleBerth, string | null>
                options={berths.sort((a, b) => a.berthId.localeCompare(b.berthId))}
                size={'small'}
                label={'Select berths'}
                value={generateSafetyPredictionForm.berthIds ?? []}
                valueFunction={(berth) => berth.berthId}
                displayFunction={(berth) => `${berth.berthId} - ${berth.name}`}
                onChange={handleBerthsChange}
                fullWidth={true}
                multiple={true}
                formControlSx={{ '.MuiOutlinedInput-input': { padding: 0.8 } }}
                menuProps={{
                  sx: { '& .MuiPaper-root': { maxHeight: '50vh' } },
                }}
                renderValue={() => (
                  <SelectedChips
                    values={generateSafetyPredictionForm.berthIds}
                    onDeletedChip={(deletedId) => handleBerthsChange(generateSafetyPredictionForm.berthIds.filter((id) => id != deletedId))}
                  />
                )}
              />
            )}
            <FormControlLabel
              control={<Checkbox checked={applyAllHistory} onChange={onApplyAllHistoryChange} />}
              label={'Apply for all history'}
              componentsProps={{ typography: { variant: 'highlightSemiBoldMedium' } }}
            />
            {!applyAllHistory && (
              <FormControlLabel
                control={<DateRange maxDate={now} setDateRange={updateDateRangeForm} />}
                label={'Date range to synchronise'}
                labelPlacement={'top'}
                componentsProps={{ typography: { variant: 'highlightSemiBoldMedium', my: 1, alignSelf: 'start' } }}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={generateSafetyPredictionForm.withMooredVesselWarnings}
                  onChange={(_, c) => updateGenerationForm('withMooredVesselWarnings', c)}
                />
              }
              componentsProps={{ typography: { variant: 'highlightSemiBoldMedium' } }}
              label={'Include moored vessels data'}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={generateSafetyPredictionForm.withDmaCaseWarnings}
                  onChange={(_, c) => updateGenerationForm('withDmaCaseWarnings', c)}
                />
              }
              componentsProps={{ typography: { variant: 'highlightSemiBoldMedium' } }}
              label={'Include DMA prediction data'}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} color={'primary'} onClick={syncHistory}>
            Synchronise
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function BerthSafetyOverview() {
  const { data, error } = useBerthManagementServiceGetBerthSafetyWarnings({ pageNumber: 1, pageSize: 100 });

  const pageTitle = 'Berth safety warnings';
  usePageTitle(pageTitle);

  if (error) {
    return <ApiErrorAlert error={error} />;
  }
  if (data) {
    return (
      <>
        <RegenerateSafetyPrediction />
        <GridOverview columns={getColumns()} title={pageTitle} data={data.berthDmaCaseSafetyWarnings} getRowId={(row) => `${row.id}`} />
      </>
    );
  }
  return <LoadingIndicator message={'Searching for berth safety warnings'} />;
}

export default BerthSafetyOverview;
