import { Dispatch, SetStateAction } from 'react';
import { SafetyPredictionTrigger } from '@/api/ui/requests';

export type BerthIdentifier = string;
export type MaritimeMobileServiceIdentity = number;
export type InternationalMaritimeOrganizationNumber = number;
export type OrganisationId = string;
export type VesselType = string;
export type VesselClass = string;
export type LengthOverAll = number;
export type DeadWeighTonnage = number;
export type Displacement = number;
export type BollardCapacity = number;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_ZONE_FORMAT = 'DD-MM-YYYY HH:mm:ss Z';
export const DATE_TIME_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export interface LatLngLiteral {
  lat: number;
  lng: number;
}

export interface ApplicationSettings {
  authentication: AuthenticationSettings;
  leaflet: LeafletSettings;
  analytics: AnalyticsSettings;
  api: ApiSettings;
  license: LicenseSettings;
  featureFlags: string[];
  environment: string;
  deployment: string;
  sentry: SentrySettings;
}

export interface SentrySettings {
  dsn: string;
  environment: string;
}

export interface LicenseSettings {
  productName: string;
}

export interface ApiSettings {
  baseUrl: string;
  managementEndpoint: string;
  uiEndpoint: string;
}

export interface AnalyticsSettings {
  token: string;
}

export interface AnalyticsEventProps {
  analyticsEventName: string;
  analyticsEventProperties: object;
}

export interface AnalyticsInputEventProps<TValue> {
  analyticsEventName: string;
  analyticsEventProperties: (value: TValue) => object | undefined;
}

export interface AuthenticationSettings {
  clientId: string;
  audience: string;
  domain: string;
  issuer: string;
  scopes: string;
}

export interface LeafletSettings {
  accessToken: string;
  mapUrl: string;
}

export interface UserSettings {
  timeZone: string;
}

export interface SmartSocietyUser {
  personId: string;
  emailAddress: string;
  organisationId: OrganisationId;
  company: string;
  smartSocietyOrganisationName: string;
  tokenIssuedAt: Date;
}

export interface Organisation {
  organisationId: OrganisationId;
  name: string;
  allowedBerths: BerthIdentifier[];
}

export interface BerthReferenceData {
  identifier: BerthIdentifier;
  name: string;
  polygon: LatLngLiteral[];
  location: LatLngLiteral;
  orientation: number;
}

export interface BerthConfiguration {
  pamLevels: number[];
  bollardCapacity: BollardCapacity | null;
}

export interface VesselReferenceData {
  mmsi: MaritimeMobileServiceIdentity;
  imo: InternationalMaritimeOrganizationNumber;
  name: string;
  type: VesselType;
  loa: LengthOverAll;
  dwt: DeadWeighTonnage;
  displacement: Displacement;
  vesselClass: VesselClass;
}

export interface StateProps<T> {
  value: T;
  setValue?: Dispatch<SetStateAction<T>>;
}

// export type LoadingCondition = 'BALLASTED' | 'HALF_LOADED' | 'LOADED';
//
// export type VesselDisposition = 'PORTSIDE' | 'STARBOARD';
//
// export type VesselMotion = 'surge' | 'sway' | 'heave' | 'yaw' | 'roll' | 'pitch';
//
// export const VesselMotions: VesselMotion[] = ['surge', 'sway', 'heave', 'yaw', 'roll', 'pitch'];
//
// export const VesselClearanceVesselTypes = ['Container', 'Tanker', 'General cargo', 'Bulker'];
//
// export type VesselClearanceVesselType = typeof VesselClearanceVesselTypes;

// These feature names have to be identical to the names in Smart Society
export enum LicenseFeatures {
  AIS_DETECTION = 'AIS detection',
  HISTORIC_OVERVIEW = 'Historic overview',
  BOLLARD_FORCES = 'Berth Safety - Bollard forces',
  PDF_DOWNLOAD = 'PDF download',
  VESSEL_CLEARANCE = 'Vessel Clearance',
  TRIAL_WARNINGS = 'Trial Warnings',
}

// This util object is used to map the SafetyPredictionTrigger to the correct graph name and motion
const motionGraphName = 'vessel-motions';
const motionGraphClassName = 'vessel-motion-chart';
export const SafetyPredictionTriggerStrings: {
  [key in SafetyPredictionTrigger]: {
    name: string;
    graphName: string;
    graphClassName?: string;
    motion?: string;
  };
} = {
  [SafetyPredictionTrigger.LINE_FORCE]: {
    name: 'Mooring line force',
    graphName: 'line-forces',
    graphClassName: 'mooring-line-chart',
  },
  [SafetyPredictionTrigger.FENDER_DEFLECTION]: {
    name: 'Fender deflection',
    graphName: 'fender-deflection',
    graphClassName: 'fender-deflection-chart',
  },
  [SafetyPredictionTrigger.BOLLARD_FORCE]: {
    name: 'Bollard force',
    graphName: 'bollard-forces',
    graphClassName: 'bollard-force-chart',
  },
  [SafetyPredictionTrigger.MOTION_SURGE]: {
    name: 'Vessel Motion - Surge',
    graphName: motionGraphName,
    graphClassName: motionGraphClassName,
    motion: 'Surge',
  },
  [SafetyPredictionTrigger.MOTION_SWAY]: {
    name: 'Vessel Motion - Sway',
    graphName: motionGraphName,
    graphClassName: motionGraphClassName,
    motion: 'Sway',
  },
  [SafetyPredictionTrigger.MOTION_YAW]: {
    name: 'Vessel Motion - Yaw',
    graphName: motionGraphName,
    graphClassName: motionGraphClassName,
    motion: 'Yaw',
  },
  [SafetyPredictionTrigger.MOTION_HEAVE]: {
    name: 'Vessel Motion - Heave',
    graphName: motionGraphName,
    graphClassName: motionGraphClassName,
    motion: 'Heave',
  },
  [SafetyPredictionTrigger.MOTION_ROLL]: {
    name: 'Vessel Motion - Roll',
    graphName: motionGraphName,
    graphClassName: motionGraphClassName,
    motion: 'Roll',
  },
  [SafetyPredictionTrigger.MOTION_PITCH]: {
    name: 'Vessel Motion - Pitch',
    graphName: motionGraphName,
    graphClassName: motionGraphClassName,
    motion: 'Pitch',
  },
};
