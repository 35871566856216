import { useState } from 'react';
import { Box, Grid, Link, Container as MuiContainer, Typography, styled } from '@mui/material';
import moment from 'moment';
import { useLocalStorage } from 'usehooks-ts';
import DisclaimerDialog from '@/parts/Disclaimer/DisclaimerDialog';

const FooterContainer = styled(MuiContainer)(() => ({
  maxHeight: 25,
  height: 12,
  fontSize: 12,
  zIndex: 0,
}));

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      {new Date().getFullYear()}&nbsp;
      <Link color="inherit" href="https://www.twinn.io/en/services/smart-mooring">
        www.twinn.io/en/services/smart-mooring
      </Link>{' '}
    </Typography>
  );
}

function TimeZone() {
  return (
    <Typography variant="body2" color="text.secondary">
      Timezone: {moment.tz.guess(true)}
    </Typography>
  );
}

function DisclaimerLink() {
  const [open, setOpen] = useState(false);
  const [accepted, setAccepted] = useLocalStorage<boolean>('accepted', false);

  function onAccept() {
    setAccepted(true);
  }

  function openDisclaimer() {
    setOpen(true);
  }

  const closeDisclaimer = () => {
    setOpen(false);
  };

  return (
    <>
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          openDisclaimer();
        }}
      >
        Disclaimer
      </Link>
      <DisclaimerDialog accepted={accepted} showDialog={open} onClose={closeDisclaimer} onAccept={onAccept} />
    </>
  );
}

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 0,
        mt: 'auto',
        position: 'relative',
        paddingLeft: '3rem',
        background: '#fefefe',
        width: '100vw',
        fontSize: '12px',
        bottom: 0,
      }}
    >
      <FooterContainer maxWidth={false} disableGutters={true}>
        <Grid container columnSpacing={2} alignItems={'center'} direction="row" justifyContent={'flex-end'}>
          <Grid item md={6} lg={6}>
            <Copyright />
          </Grid>
          <Grid item md={3} lg={3}>
            <TimeZone />
          </Grid>
          <Grid item md={2} lg={2}>
            <DisclaimerLink />
          </Grid>
        </Grid>
      </FooterContainer>
    </Box>
  );
}

export default Footer;
