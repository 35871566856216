import { createSearchParams, useNavigate } from 'react-router-dom';
import { Moment } from 'moment-timezone';
import type { LoadingCondition, SafetyPredictionTrigger, VesselDisposition } from '@/api/ui/requests';
import { SafetyPredictionTriggerStrings } from '@/types';

type BerthSafetyNavigationProps = {
  berthId: string;
  isOperationalVessel: boolean;
  dmaCaseId?: string;
  loadingCondition?: LoadingCondition;
  vesselDisposition?: VesselDisposition;
  trigger?: SafetyPredictionTrigger;
  requestTimestamp?: Moment;
  days?: number;
  vesselMmsi?: string;
};

const searchParamsBuilder = (searchParams: URLSearchParams, name: string, value?: string) => {
  value && searchParams.set(name, value);
};

// if predictionTimestamp is sooner than 2:00, move left 2 hours from start of the day.
// if predictionTimestamp is later than 23:00, move right 2 hours start of the day.
// So that, the graph will have a little offset util the marked timestamp.
const getTimeMargin = (timestamp: Moment, margin: number): number => {
  const hour = timestamp?.hour();
  if (hour < margin) return -margin;
  if (hour >= 24 - margin) return margin;
  return 0;
};

function useToBerthSafetyNavigate() {
  const navigate = useNavigate();

  const navigateToBerthSafety = ({
    berthId,
    loadingCondition,
    trigger,
    dmaCaseId,
    isOperationalVessel,
    vesselDisposition,
    requestTimestamp,
    days,
    vesselMmsi,
  }: BerthSafetyNavigationProps) => {
    const warningTrigger = SafetyPredictionTriggerStrings[trigger];

    const timeMargin = getTimeMargin(requestTimestamp, 2);
    const searchTimestamp = requestTimestamp?.startOf('day')?.add(timeMargin, 'hours');

    const searchParams = createSearchParams();
    searchParamsBuilder(searchParams, 'mmsi', vesselMmsi);
    searchParamsBuilder(searchParams, 'dmaCaseId', dmaCaseId);
    searchParamsBuilder(searchParams, 'loadingCondition', loadingCondition);
    searchParamsBuilder(searchParams, 'date', searchTimestamp?.toISOString());
    searchParamsBuilder(searchParams, 'disposition', vesselDisposition);
    searchParamsBuilder(searchParams, 'graph', warningTrigger?.graphName);
    searchParamsBuilder(searchParams, 'motion', warningTrigger?.motion);
    searchParamsBuilder(searchParams, 'period', days?.toString());
    searchParamsBuilder(searchParams, 'tz', searchTimestamp?.tz());

    const basePath = `/safety-prediction/${isOperationalVessel ? 'operational' : 'simulation'}/berths/${berthId}`;
    navigate({
      pathname: basePath,
      search: searchParams.toString(),
    });
  };

  return {
    navigateToBerthSafety,
  };
}

export default useToBerthSafetyNavigate;
